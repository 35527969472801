<template>
  <div class="main-history-container">
    <div class="container">
      <h2 class="title text-primary">AI Creative Analysis History</h2>
      <p class="subtitle">
        Generated Reports analysis to improve your creative assets.
      </p>
      <div
        v-for="(creative, index) in creatives"
        :key="index"
        class="creative-card"
      >
        <div class="creative-card-image" v-if="creative.imageUrl">
          <img
            class="image-align"
            :src="creative.imageUrl"
            v-if="creative.imageUrl"
            alt="Creative Image"
          />
        </div>
        <div class="creative-card-content">
          <div class="creative-card-actions mb-2">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="openReportCardModal(creative)"
            >
              <b-icon
                icon="eye"
                font-scale="1.2"
                style="margin-right: 3px;"
              />View Report</b-button
            >
            <b-button
              style="margin-left:5px;"
              size="sm"
              variant="outline-primary"
              @click="deleteReportsOpen(creative.title)"
            >
              <b-icon
                icon="trash"
                font-scale="1.2"
                style="margin-right:5px;"
              />Delete Report</b-button
            >
          </div>
          <div class="mb-4">
            <h4>{{ creative.title }}</h4>
            <p class="subtitle-summary">{{ creative.one_line_summary }}</p>
            <p class="subtitle-summary">
              <strong>Keywords:</strong>
              {{ creative && creative.tags.join(",") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      no-close-on-backdrop
      id="modal-center"
      hide-footer
      hide-header
      centered
      size="small"
      v-model="deleteModal"
    >
      <b-card-text class="mt-2">
        <div class="text-center">
          <feather-icon
            icon="AlertTriangleIcon"
            color="#FF5959"
            size="60"
          ></feather-icon>
        </div>
        <div>
          <h6 class="deletemodal-text">
            Are you sure you want to delete this report?
          </h6>
        </div>
        <div class="buttons">
          <b-button
            class="button-margin"
            style="width: 210px"
            variant="outline-secondary"
            @click="closeDeleteModal"
          >
            Cancel
          </b-button>
          <b-button
            variant="danger"
            @click="deleteReports"
            style="width: 210px"
          >
            Confirm
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
  </div>
  
</template>

<script>
import { BButton, BIcon ,BModal,BCardText} from "bootstrap-vue";
export default {
  data() {
    return {
      deleteModal:false,
      deleteReportUrl:""
    };
  },
  components: {
    BButton,
    BIcon,
    BModal,BCardText
  },
  props: {
    creatives: {
      type: Array,
    },
  },
  methods: {
    closeDeleteModal(){
      this.deleteModal = false;
    },
    deleteReportsOpen(image) {
      this.deleteReportUrl = image
      this.deleteModal = true;
    },
    deleteReports() {
      this.$emit("deleteReports", this.deleteReportUrl);
      this.deleteModal = false;
    },
    openReportCardModal(data) {
      this.$emit("viewReports", data);
    },
    getValidImageUrl(url) {
      return url || ""; // Ensure it's a valid string
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.main-history-container {
  .container {
    max-width: 1200px;
    margin: 10px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .creative-card {
    display: flex;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    align-items: stretch;
    gap: 10px;
    min-height: 200px;
  }

  .image-align {
    margin-left: auto;
    margin-right: auto;
  }

  .creative-card-image {
    width: 350px;
    padding: 20px;
    border: 0.5px dashed rgb(175, 175, 175);
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      height: 160px;
      border-radius: 5px;
      display: block;
      object-fit: cover;
    }
  }

  .creative-card-content {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .creative-card-actions {
    text-align: right;
  }

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
  }

  .subtitle {
    text-align: center;
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    .creative-card {
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 500px !important;
    }

    .creative-card-image {
      width: 100%;
      padding: 10px;
    }

    .creative-card-content {
      padding: 15px;
    }

    .creative-card-actions {
      text-align: center;
    }

    .title {
      font-size: 20px;
    }

    .subtitle {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    .creative-card {
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 500px !important;
    }

    .creative-card-image {
      width: 100%;
      padding: 10px;
    }

    .creative-card-content {
      padding: 15px;
    }

    .creative-card-actions {
      text-align: center;
    }
  }

  @media (max-width: 480px) {
    .creative-card {
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 500px !important;
    }

    .creative-card-image {
      width: 100%;
      padding: 10px;
    }

    .creative-card-content {
      padding: 15px;
    }

    .creative-card-actions {
      text-align: center;
    }

    .title {
      font-size: 18px;
    }

    .subtitle {
      font-size: 10px;
    }
  }
}
</style>