<template>
  <div>
    <div class="table-container">
      <div v-if="TableType === 'creativeAnalysisData'" class="keywordTableContainer">
        <div v-if="isAnalysisDataFetched" class="spinnerCentered">
          <multiChannelSpinner />
        </div>
        <div v-else-if="clientsLength === 0 && PaidData.length === 0" class="spinnerCentered" style="padding: 100px;">
          <div class="onboarding-container">
            <p>Please connect your Google/Meta account to proceed further.</p>
            <b-button variant="primary" size="sm" @click="onboardingClick">
              Complete Your Onboarding
            </b-button>
          </div>
        </div>
        <div v-else-if="PaidData.length === 0 && clientsLength > 0">
          <div>
            <div
              style="text-align: center; justify-content: center; align-items: center; margin-left: 0%; margin-top: 10%; height: 20vh;">
              
                <p v-if="selectedIntegration && (selectedIntegration.googleAds === false && selectedIntegration.facebook === false)" 
                class="text-primary">
                No data available for the selected client. Please integrate a channel to proceed.
              </p>

              <p v-else-if="selectedIntegration && (selectedIntegration.facebook === false && selectedDataViewType === 'Meta')" 
                 class="text-primary">
                No data available for the selected client. Please integrate Meta-Ads channel to proceed.
              </p>
              <p v-else-if="selectedIntegration && ( selectedIntegration.googleAds === false && selectedDataViewType === 'Google-Ads')" 
                  class="text-primary">
                No data available for the selected client. Please integrate Google-Ads channel to proceed.
              </p>
              <p v-else>No data available for the selected client. Please Select an account to proceed.</p>
              <b-link v-if="selectedIntegration && (selectedIntegration.googleAds === false || selectedIntegration.facebook === false)" 
                      :to="`/client/${selectedSlug}`">
                <b-card-text class="xs text-center text-capitalize mt-1">
                  <span style="text-decoration: underline; color: #6b6b6b !important;">
                    click here to connect your data
                  </span>
                </b-card-text>
              </b-link>
              
             
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="this.selectedBusinessType == 'leadgen'">
            <table class="creativeTableStyle" v-if="this.ChannelType === 'Meta'">
              <thead>
                <tr>
                  <th style="border: 0.5px solid #e7e7e7;" :style="{ backgroundColor: firstColumnColor }"
                    class="thCreativesHeader">
                    {{ leadsCreativeAnalysisData[0].title }}
                  </th>
                  <th
                    style="border: 0.5px solid #e7e7e7; min-width:100px !important; max-width:100px !important; padding:10px 5px !important;"
                    v-for="(col_one_header,
                      fistIndex) in leadsCreativeAnalysisData.slice(1)" class="thCreativesHeader" :key="fistIndex"
                    :style="{
                      backgroundColor: col_one_header ? col_one_header.color : '',
                    }">
                    {{ leadsCreativeAnalysisData[fistIndex + 1].title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(first_row, firstRowIndex) in displayedData">
                  <!-- 1 - parent level -->
                  <tr :key="firstRowIndex" :class="{ opened: opened.includes(firstRowIndex) }" style="padding: 0px;">
                  <template v-if=" firstRowIndex,
                    first_row && first_row.adset.length > 0">
                    <td style="max-width: 500px !important; min-width: 500px !important;" @click="
                    toggle(
                      firstRowIndex,
                      first_row && first_row.adset
                    )
                    ">
                    <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                      <b-button variant="link" style="padding:0px 2px !important">
                        <span
                          style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                          <div style="display: flex;">
                            <div>
                              <feather-icon :icon="opened.includes(firstRowIndex)
                                ? 'MinusCircleIcon'
                                : 'PlusCircleIcon'
                                " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                            </div>
                            <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                              <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                            </div>
                            <div>
                              <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                {{ first_row && first_row.campaignName }}
                              </p>
                              <p style="margin-top: -10px;">
                              <div style="display: flex;gap:8px">
                                <span
                                  style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                  <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                <span
                                  style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                  <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                              </div>
                              </p>
                            </div>
                          </div>
                          <!-- <span> {{
                            first_row.campaigns[firstRowIndex].campaignName
                          }}</span> -->
                        </span>
                      </b-button>
                    </div>
                  </td>
                </template>
                <template v-else>
                  <td style="max-width: 500px !important; min-width: 500px !important;">
                  <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                    <b-button variant="link" style="padding:0px 2px !important">
                      <span
                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                        <div style="display: flex;">
                          <div v-b-tooltip.hover
                          title="No ad set were found for this campaign.">
                            <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                          </div>
                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                          </div>
                          <div>
                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                              {{ first_row && first_row.campaignName }}
                            </p>
                            <p style="margin-top: -10px;">
                            <div style="display: flex;gap:8px">
                              <span
                                style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                              <span
                                style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                            </div>
                            </p>
                          </div>
                        </div>
                        <!-- <span> {{
                          first_row.campaigns[firstRowIndex].campaignName
                        }}</span> -->
                      </span>
                    </b-button>
                  </div>
                </td>
                </template>
                    <!-- <td style="max-width: 500px !important; min-width: 500px !important;" @click="
                      toggle(
                        firstRowIndex,
                        first_row && first_row.adset
                      )
                      ">
                      <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                        <b-button variant="link" style="padding:0px 2px !important">
                          <span
                            style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                            <div style="display: flex;">
                              <div>
                                <feather-icon :icon="opened.includes(firstRowIndex)
                                  ? 'MinusCircleIcon'
                                  : 'PlusCircleIcon'
                                  " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                              </div>
                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                              </div>
                              <div>
                                <p style="word-break: break-word; white-space: pre-wrap;">
                                  {{ first_row && first_row.campaignName }}
                                </p>
                                <p style="margin-top: -10px;">
                                <div style="display: flex;gap:8px">
                                  <span
                                    style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                    <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                  <span
                                    style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                    <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                                </div>
                                </p>
                              </div>
                            </div>
                          </span>
                        </b-button>
                      </div>
                    </td> -->
                    <td class="td-width">
                      {{ first_row && first_row.spend }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.impressions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.clicks }}
                    </td>

                    <td class="td-width">
                      {{ first_row && first_row.cpm }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.ctr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.conversions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cvr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td>
                  </tr>
                  <!-- 2 - child level -->
                  <tr class="animate" v-if="opened.includes(firstRowIndex)">
                    <td colspan="14">
                      <div class="pl-3">
                        <table>
                          <tbody>
                            <template v-for="(row_secound_value,
                              rowSecIndex) in displayedInsideData">
                              <tr :key="rowSecIndex" :class="{ subopened: subOpened.includes(rowSecIndex) }">
                                <template v-if="rowSecIndex, row_secound_value && row_secound_value.ads && row_secound_value.ads.length > 0">
                                  <td style="width: 36.4%;" @click="
                                  subToggle(rowSecIndex, row_secound_value.ads)
                                  ">
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div>
                                            <feather-icon :icon="subOpened.includes(rowSecIndex)
                                              ? 'MinusCircleIcon'
                                              : 'PlusCircleIcon'
                                              " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adsetName &&
                                                row_secound_value.adsetName.length > 50
                                                ? row_secound_value.adsetName.substring(0, 50) + '\n' +
                                                row_secound_value.adsetName.substring(50)
                                                : row_secound_value.adsetName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />
                                                Ads-Set</span>
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                </template>
                                <template v-else>
                                  <td style="width: 36.4%;">
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div  v-b-tooltip.hover
                                          title="No ads were found for this campaign.">
                                            <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adsetName &&
                                                row_secound_value.adsetName.length > 50
                                                ? row_secound_value.adsetName.substring(0, 50) + '\n' +
                                                row_secound_value.adsetName.substring(50)
                                                : row_secound_value.adsetName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />
                                                Ads-Set</span>
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                </template>
                                <td class="td-width">
                                  {{ row_secound_value.spend }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.impressions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.clicks }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpm }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.ctr }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.conversions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cvr }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpcl }}
                                </td>
                              </tr>
                              <tr class="animate" v-if="subOpened.includes(rowSecIndex)">
                                <td colspan="14">
                                  <div class="pl-3">
                                    <table>
                                      <tbody>
                                        <tr v-for="(third_row,
                                          rowThirdIndex) in displayedSubInsideData" :key="rowThirdIndex">
                                          <td style="width:34.2% !important">
                                            <div style="display: flex;">
                                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                                  color="skyblue" />
                                              </div>
                                              <div>
                                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                                  {{ third_row && third_row.adName &&
                                                    third_row.adName.length > 50
                                                    ? third_row.adName.substring(0, 50) + '\n' +
                                                    third_row.adName.substring(50)
                                                    : third_row.adName }}
                                                </p>
                                                <p style="margin-top: -10px;">
                                                <div style="display: flex; justify-content:space-between">
                                                  <span
                                                    style="border: 1px solid skyblue; border-radius:10px; padding:3px; color:skyblue; font-weight:bold">
                                                    <feather-icon icon="LayoutIcon" size="12" color="skyblue" />
                                                    Ad</span>
                                                  <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                      <feather-icon
                                                      icon="ActivityIcon"
                                                      size="12"
                                                    /> Active</span> -->
                                                </div>
                                                </p>
                                              </div>
                                            </div>
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.spend }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.impressions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.clicks }}
                                          </td>

                                          <td class="td-width">
                                            {{ third_row.cpm }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.ctr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.conversions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cvr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpcl }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div style="justify-content: center; margin-top: 10px; height: 1;">
                                    <b-pagination v-model="subCurrentTablePageInside"
                                      :total-rows="subTotalPaidRowsInside" :per-page="subTotalPaidInside" first-number
                                      last-number align="right" prev-class="prev-item" next-class="next-item"
                                      class="mt-1 mb-0"></b-pagination>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div style="justify-content: center; margin-top: 10px; height: 1;">
                        <b-pagination v-model="currentTablePageInside" :total-rows="totalPaidRowsInside"
                          :per-page="totalPaidInside" first-number last-number align="right" prev-class="prev-item"
                          next-class="next-item" class="mt-1 mb-0"></b-pagination>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <table class="creativeTableStyle" v-if="this.ChannelType === 'Google-Ads'">
              <thead>
                <tr>
                  <th style="border: 0.5px solid #e7e7e7;" :style="{ backgroundColor: firstColumnColor }"
                    class="thCreativesHeader">
                    {{ leadAoogleCreativeAnalysisData[0].title }}
                  </th>
                  <th
                    style="border: 0.5px solid #e7e7e7; min-width:100px !important; max-width:100px !important;padding:12px 5px !important"
                    v-for="(col_one_header,
                      fistIndex) in leadAoogleCreativeAnalysisData.slice(1)" class="thCreativesHeader" :key="fistIndex"
                    :style="{
                      backgroundColor: col_one_header ? col_one_header.color : '',
                    }">
                    {{ leadAoogleCreativeAnalysisData[fistIndex + 1].title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(first_row, firstRowIndex) in displayedData">
                  <!-- 1 - parent level -->
                  <tr :key="firstRowIndex" :class="{ opened: opened.includes(firstRowIndex) }" style="padding: 0px;">
                    <template v-if="firstRowIndex,  first_row && first_row.adGroups && first_row.adGroups.length > 0">
                      <td style="max-width: 500px !important; min-width: 500px !important;" @click="
                      toggle(
                        firstRowIndex,
                        first_row && first_row.adGroups
                      )
                      ">
                      <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                        <b-button variant="link" style="padding:0px 2px !important">
                          <span
                            style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                            <div style="display: flex;">
                              <div>
                                <feather-icon :icon="opened.includes(firstRowIndex)
                                  ? 'MinusCircleIcon'
                                  : 'PlusCircleIcon'
                                  " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                              </div>
                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                              </div>
                              <div>
                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                  {{ first_row && first_row.campaignName }}
                                </p>

                                <p style="margin-top: -10px;">
                                <div style="display: flex;gap:8px">
                                  <span
                                    style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                    <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                  <span
                                    style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                    <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                                </div>
                                </p>
                              </div>
                            </div>
                            <!-- <span> {{
                              first_row.campaigns[firstRowIndex].campaignName
                            }}</span> -->
                          </span>
                        </b-button>
                      </div>
                    </td>
                    </template>
                    <template v-else>
                      <td style="max-width: 500px !important; min-width: 500px !important;"
                     >
                      <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                        <b-button variant="link" style="padding:0px 2px !important">
                          <span
                            style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                            <div style="display: flex;">
                              <div  v-b-tooltip.hover
                              title="No ad groups were found for this campaign.">
                                <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                              </div>
                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                              </div>
                              <div>
                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                  {{ first_row && first_row.campaignName }}
                                </p>

                                <p style="margin-top: -10px;">
                                <div style="display: flex;gap:8px">
                                  <span
                                    style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                    <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                  <span
                                    style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                    <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                                </div>
                                </p>
                              </div>
                            </div>
                            <!-- <span> {{
                              first_row.campaigns[firstRowIndex].campaignName
                            }}</span> -->
                          </span>
                        </b-button>
                      </div>
                    </td>
                    </template>
                    <td class="td-width">
                      {{ first_row && first_row.costMicros }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.impressions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.clicks }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpm }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.ctr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.conversions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cvr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td>
                  </tr>
                  <!-- 2 - child level -->
                  <tr class="animate" v-if="opened.includes(firstRowIndex)">
                    <td colspan="14">
                      <div class="pl-3">
                        <table>
                          <tbody>
                            <template v-for="(row_secound_value,
                              rowSecIndex) in displayedInsideData">
                              <tr :key="rowSecIndex" :class="{ subopened: subOpened.includes(rowSecIndex) }">
                                <template  v-if="rowSecIndex, row_secound_value &&  row_secound_value.adGroupAds && row_secound_value.adGroupAds.length > 0">
                                  <td style="width: 36.4%;" @click="
                                  subToggle(rowSecIndex, row_secound_value.adGroupAds)
                                  ">
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div>
                                            <feather-icon :icon="subOpened.includes(rowSecIndex)
                                              ? 'MinusCircleIcon'
                                              : 'PlusCircleIcon'
                                              " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <!-- <p style="margin-top: 5px;">
                                              {{ row_secound_value.adGroupName }}
                                            </p> -->
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adGroupName &&
                                                row_secound_value.adGroupName.length > 50
                                                ? row_secound_value.adGroupName.substring(0, 50) + '\n' +
                                                row_secound_value.adGroupName.substring(50)
                                                : row_secound_value.adGroupName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />Ad
                                                Groups</span>
                                              <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                  <feather-icon
                                                  icon="ActivityIcon"
                                                  size="12"
                                                /> Active</span> -->
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                </template>
                                <template v-else>
                                  <td style="width: 36.4%;" >
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div  v-b-tooltip.hover
                                          title="No ad group ads were found for this campaign.">
                                            <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <!-- <p style="margin-top: 5px;">
                                              {{ row_secound_value.adGroupName }}
                                            </p> -->
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adGroupName &&
                                                row_secound_value.adGroupName.length > 50
                                                ? row_secound_value.adGroupName.substring(0, 50) + '\n' +
                                                row_secound_value.adGroupName.substring(50)
                                                : row_secound_value.adGroupName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />Ad
                                                Groups</span>
                                              <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                  <feather-icon
                                                  icon="ActivityIcon"
                                                  size="12"
                                                /> Active</span> -->
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                </template>
                                <!-- <td>
                                  {{ row_secound_value.adsetName }}
                                </td> -->
                                <td class="td-width">
                                  {{ row_secound_value.costMicros }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.impressions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.clicks }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpm }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.ctr }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.conversions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cvr }}
                                </td>

                                <td class="td-width">
                                  {{ row_secound_value.cpcl }}
                                </td>
                              </tr>
                              <tr class="animate" v-if="subOpened.includes(rowSecIndex)">
                                <td colspan="14">
                                  <!-- <div style="margin:5px; float: right;">
                                <b-button   class="exportButton"  size="sm" variant="primary" @click="exportData(displayedInsideData)"
                                ><span style="font-weight: bold;">
                                  Export Data
                                  <feather-icon
                                    icon="DownloadIcon"
                                    size="15"
                                    style="margin-left: 5px;"/></span
                              ></b-button>
                              </div> -->
                                  <div class="pl-3">
                                    <table>
                                      <!-- <thead>
                                        <tr>
                                          <th
                                            v-for="(col_three_cols,
                                            thirdRow) in addSubCreativesData"
                                            class="thCreativesHeader"
                                            :key="thirdRow"
                                            :style="{
                                              backgroundColor: col_three_cols
                                                ? col_three_cols.color
                                                : '',
                                            }"
                                          >
                                            {{ addSubCreativesData[thirdRow].title }}
                                          </th>
                                        </tr>
                                      </thead> -->
                                      <tbody>
                                        <tr v-for="(third_row,
                                          rowThirdIndex) in displayedSubInsideData" :key="rowThirdIndex">
                                          <td style="width:34.2% !important">
                                            <div style="display: flex;">
                                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                                  color="skyblue" />
                                              </div>
                                              <div>
                                                <!-- <p style="margin-top: 5px;">
                                                  {{ third_row.adGroupAdName }}
                                                </p> -->
                                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                                  {{ third_row && third_row.adGroupAdName &&
                                                    third_row.adGroupAdName.length > 50
                                                    ? third_row.adGroupAdName.substring(0, 50) + '\n' +
                                                    third_row.adGroupAdName.substring(50)
                                                    : third_row.adGroupAdName }}
                                                </p>
                                                <p style="margin-top: -10px;">
                                                <div style="display: flex; justify-content:space-between">
                                                  <span
                                                    style="border: 1px solid skyblue; border-radius:10px; padding:3px; color:skyblue; font-weight:bold">
                                                    <feather-icon icon="LayoutIcon" size="12" color="skyblue" />
                                                    Ad</span>
                                                </div>
                                                </p>
                                              </div>
                                            </div>
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.costMicros }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.impressions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.clicks }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpm }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.ctr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.conversions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cvr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpcl }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div style="justify-content: center; margin-top: 10px; height: 1;">
                                    <b-pagination v-model="subCurrentTablePageInside"
                                      :total-rows="subTotalPaidRowsInside" :per-page="subTotalPaidInside" first-number
                                      last-number align="right" prev-class="prev-item" next-class="next-item"
                                      class="mt-1 mb-0"></b-pagination>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div style="justify-content: center; margin-top: 10px; height: 1;">
                        <b-pagination v-model="currentTablePageInside" :total-rows="totalPaidRowsInside"
                          :per-page="totalPaidInside" first-number last-number align="right" prev-class="prev-item"
                          next-class="next-item" class="mt-1 mb-0"></b-pagination>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div v-else-if="this.selectedBusinessType == 'leadform'">
            <table class="creativeTableStyle" v-if="this.ChannelType === 'Meta'">
              <thead>
                <tr>
                  <th style="border: 0.5px solid #e7e7e7;" :style="{ backgroundColor: firstColumnColor }"
                    class="thCreativesHeader">
                    {{ leadsCreativeAnalysisData[0].title }}
                  </th>
                  <th
                    style="border: 0.5px solid #e7e7e7; min-width:100px !important; max-width:100px !important; padding:10px 5px !important;"
                    v-for="(col_one_header,
                      fistIndex) in leadsCreativeAnalysisData.slice(1)" class="thCreativesHeader" :key="fistIndex"
                    :style="{
                      backgroundColor: col_one_header ? col_one_header.color : '',
                    }">
                    {{ leadsCreativeAnalysisData[fistIndex + 1].title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(first_row, firstRowIndex) in displayedData">
                  <!-- 1 - parent level -->
                  <tr :key="firstRowIndex" :class="{ opened: opened.includes(firstRowIndex) }" style="padding: 0px;">
                  <template v-if=" firstRowIndex,
                    first_row && first_row.adset.length > 0">
                    <td style="max-width: 500px !important; min-width: 500px !important;" @click="
                    toggle(
                      firstRowIndex,
                      first_row && first_row.adset
                    )
                    ">
                    <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                      <b-button variant="link" style="padding:0px 2px !important">
                        <span
                          style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                          <div style="display: flex;">
                            <div>
                              <feather-icon :icon="opened.includes(firstRowIndex)
                                ? 'MinusCircleIcon'
                                : 'PlusCircleIcon'
                                " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                            </div>
                            <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                              <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                            </div>
                            <div>
                              <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                {{ first_row && first_row.campaignName }}
                              </p>
                              <p style="margin-top: -10px;">
                              <div style="display: flex;gap:8px">
                                <span
                                  style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                  <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                <span
                                  style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                  <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                              </div>
                              </p>
                            </div>
                          </div>
                          <!-- <span> {{
                            first_row.campaigns[firstRowIndex].campaignName
                          }}</span> -->
                        </span>
                      </b-button>
                    </div>
                  </td>
                </template>
                <template v-else>
                  <td style="max-width: 500px !important; min-width: 500px !important;">
                  <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                    <b-button variant="link" style="padding:0px 2px !important">
                      <span
                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                        <div style="display: flex;">
                          <div v-b-tooltip.hover
                          title="No ad set were found for this campaign.">
                            <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                          </div>
                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                          </div>
                          <div>
                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                              {{ first_row && first_row.campaignName }}
                            </p>
                            <p style="margin-top: -10px;">
                            <div style="display: flex;gap:8px">
                              <span
                                style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                              <span
                                style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                            </div>
                            </p>
                          </div>
                        </div>
                        <!-- <span> {{
                          first_row.campaigns[firstRowIndex].campaignName
                        }}</span> -->
                      </span>
                    </b-button>
                  </div>
                </td>
                </template>
                    <!-- <td style="max-width: 500px !important; min-width: 500px !important;" @click="
                      toggle(
                        firstRowIndex,
                        first_row && first_row.adset
                      )
                      ">
                      <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                        <b-button variant="link" style="padding:0px 2px !important">
                          <span
                            style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                            <div style="display: flex;">
                              <div>
                                <feather-icon :icon="opened.includes(firstRowIndex)
                                  ? 'MinusCircleIcon'
                                  : 'PlusCircleIcon'
                                  " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                              </div>
                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                              </div>
                              <div>
                                <p style="word-break: break-word; white-space: pre-wrap;">
                                  {{ first_row && first_row.campaignName }}
                                </p>
                                <p style="margin-top: -10px;">
                                <div style="display: flex;gap:8px">
                                  <span
                                    style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                    <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                  <span
                                    style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                    <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                                </div>
                                </p>
                              </div>
                            </div>
                          </span>
                        </b-button>
                      </div>
                    </td> -->
                    <td class="td-width">
                      {{ first_row && first_row.spend }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.impressions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.clicks }}
                    </td>

                    <td class="td-width">
                      {{ first_row && first_row.cpm }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.ctr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.conversions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cvr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td>
                  </tr>
                  <!-- 2 - child level -->
                  <tr class="animate" v-if="opened.includes(firstRowIndex)">
                    <td colspan="14">
                      <div class="pl-3">
                        <table>
                          <tbody>
                            <template v-for="(row_secound_value,
                              rowSecIndex) in displayedInsideData">
                              <tr :key="rowSecIndex" :class="{ subopened: subOpened.includes(rowSecIndex) }">
                                <template v-if="rowSecIndex, row_secound_value && row_secound_value.ads && row_secound_value.ads.length > 0">
                                  <td style="width: 36.4%;" @click="
                                  subToggle(rowSecIndex, row_secound_value.ads)
                                  ">
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div>
                                            <feather-icon :icon="subOpened.includes(rowSecIndex)
                                              ? 'MinusCircleIcon'
                                              : 'PlusCircleIcon'
                                              " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adsetName &&
                                                row_secound_value.adsetName.length > 50
                                                ? row_secound_value.adsetName.substring(0, 50) + '\n' +
                                                row_secound_value.adsetName.substring(50)
                                                : row_secound_value.adsetName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />
                                                Ads-Set</span>
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                </template>
                                <template v-else>
                                  <td style="width: 36.4%;">
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div  v-b-tooltip.hover
                                          title="No ads were found for this campaign.">
                                            <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adsetName &&
                                                row_secound_value.adsetName.length > 50
                                                ? row_secound_value.adsetName.substring(0, 50) + '\n' +
                                                row_secound_value.adsetName.substring(50)
                                                : row_secound_value.adsetName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />
                                                Ads-Set</span>
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                </template>
                                <td class="td-width">
                                  {{ row_secound_value.spend }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.impressions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.clicks }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpm }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.ctr }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.conversions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cvr }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpcl }}
                                </td>
                              </tr>
                              <tr class="animate" v-if="subOpened.includes(rowSecIndex)">
                                <td colspan="14">
                                  <div class="pl-3">
                                    <table>
                                      <tbody>
                                        <tr v-for="(third_row,
                                          rowThirdIndex) in displayedSubInsideData" :key="rowThirdIndex">
                                          <td style="width:34.2% !important">
                                            <div style="display: flex;">
                                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                                  color="skyblue" />
                                              </div>
                                              <div>
                                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                                  {{ third_row && third_row.adName &&
                                                    third_row.adName.length > 50
                                                    ? third_row.adName.substring(0, 50) + '\n' +
                                                    third_row.adName.substring(50)
                                                    : third_row.adName }}
                                                </p>
                                                <p style="margin-top: -10px;">
                                                <div style="display: flex; justify-content:space-between">
                                                  <span
                                                    style="border: 1px solid skyblue; border-radius:10px; padding:3px; color:skyblue; font-weight:bold">
                                                    <feather-icon icon="LayoutIcon" size="12" color="skyblue" />
                                                    Ad</span>
                                                  <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                      <feather-icon
                                                      icon="ActivityIcon"
                                                      size="12"
                                                    /> Active</span> -->
                                                </div>
                                                </p>
                                              </div>
                                            </div>
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.spend }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.impressions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.clicks }}
                                          </td>

                                          <td class="td-width">
                                            {{ third_row.cpm }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.ctr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.conversions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cvr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpcl }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div style="justify-content: center; margin-top: 10px; height: 1;">
                                    <b-pagination v-model="subCurrentTablePageInside"
                                      :total-rows="subTotalPaidRowsInside" :per-page="subTotalPaidInside" first-number
                                      last-number align="right" prev-class="prev-item" next-class="next-item"
                                      class="mt-1 mb-0"></b-pagination>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div style="justify-content: center; margin-top: 10px; height: 1;">
                        <b-pagination v-model="currentTablePageInside" :total-rows="totalPaidRowsInside"
                          :per-page="totalPaidInside" first-number last-number align="right" prev-class="prev-item"
                          next-class="next-item" class="mt-1 mb-0"></b-pagination>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <table class="creativeTableStyle" v-if="this.ChannelType === 'Google-Ads'">
              <thead>
                <tr>
                  <th style="border: 0.5px solid #e7e7e7;" :style="{ backgroundColor: firstColumnColor }"
                    class="thCreativesHeader">
                    {{ leadAoogleCreativeAnalysisData[0].title }}
                  </th>
                  <th
                    style="border: 0.5px solid #e7e7e7; min-width:100px !important; max-width:100px !important;padding:12px 5px !important"
                    v-for="(col_one_header,
                      fistIndex) in leadAoogleCreativeAnalysisData.slice(1)" class="thCreativesHeader" :key="fistIndex"
                    :style="{
                      backgroundColor: col_one_header ? col_one_header.color : '',
                    }">
                    {{ leadAoogleCreativeAnalysisData[fistIndex + 1].title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(first_row, firstRowIndex) in displayedData">
                  <!-- 1 - parent level -->
                  <tr :key="firstRowIndex" :class="{ opened: opened.includes(firstRowIndex) }" style="padding: 0px;">
                    <template v-if="firstRowIndex,  first_row && first_row.adGroups && first_row.adGroups.length > 0">
                      <td style="max-width: 500px !important; min-width: 500px !important;" @click="
                      toggle(
                        firstRowIndex,
                        first_row && first_row.adGroups
                      )
                      ">
                      <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                        <b-button variant="link" style="padding:0px 2px !important">
                          <span
                            style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                            <div style="display: flex;">
                              <div>
                                <feather-icon :icon="opened.includes(firstRowIndex)
                                  ? 'MinusCircleIcon'
                                  : 'PlusCircleIcon'
                                  " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                              </div>
                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                              </div>
                              <div>
                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                  {{ first_row && first_row.campaignName }}
                                </p>

                                <p style="margin-top: -10px;">
                                <div style="display: flex;gap:8px">
                                  <span
                                    style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                    <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                  <span
                                    style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                    <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                                </div>
                                </p>
                              </div>
                            </div>
                            <!-- <span> {{
                              first_row.campaigns[firstRowIndex].campaignName
                            }}</span> -->
                          </span>
                        </b-button>
                      </div>
                    </td>
                    </template>
                    <template v-else>
                      <td style="max-width: 500px !important; min-width: 500px !important;"
                     >
                      <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                        <b-button variant="link" style="padding:0px 2px !important">
                          <span
                            style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                            <div style="display: flex;">
                              <div  v-b-tooltip.hover
                              title="No ad groups were found for this campaign.">
                                <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                              </div>
                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                              </div>
                              <div>
                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                  {{ first_row && first_row.campaignName }}
                                </p>

                                <p style="margin-top: -10px;">
                                <div style="display: flex;gap:8px">
                                  <span
                                    style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                    <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                  <span
                                    style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                    <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                                </div>
                                </p>
                              </div>
                            </div>
                            <!-- <span> {{
                              first_row.campaigns[firstRowIndex].campaignName
                            }}</span> -->
                          </span>
                        </b-button>
                      </div>
                    </td>
                    </template>
                    <td class="td-width">
                      {{ first_row && first_row.costMicros }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.impressions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.clicks }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpm }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.ctr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.conversions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cvr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td>
                  </tr>
                  <!-- 2 - child level -->
                  <tr class="animate" v-if="opened.includes(firstRowIndex)">
                    <td colspan="14">
                      <div class="pl-3">
                        <table>
                          <tbody>
                            <template v-for="(row_secound_value,
                              rowSecIndex) in displayedInsideData">
                              <tr :key="rowSecIndex" :class="{ subopened: subOpened.includes(rowSecIndex) }">
                                <template  v-if="rowSecIndex, row_secound_value &&  row_secound_value.adGroupAds && row_secound_value.adGroupAds.length > 0">
                                  <td style="width: 36.4%;" @click="
                                  subToggle(rowSecIndex, row_secound_value.adGroupAds)
                                  ">
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div>
                                            <feather-icon :icon="subOpened.includes(rowSecIndex)
                                              ? 'MinusCircleIcon'
                                              : 'PlusCircleIcon'
                                              " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <!-- <p style="margin-top: 5px;">
                                              {{ row_secound_value.adGroupName }}
                                            </p> -->
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adGroupName &&
                                                row_secound_value.adGroupName.length > 50
                                                ? row_secound_value.adGroupName.substring(0, 50) + '\n' +
                                                row_secound_value.adGroupName.substring(50)
                                                : row_secound_value.adGroupName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />Ad
                                                Groups</span>
                                              <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                  <feather-icon
                                                  icon="ActivityIcon"
                                                  size="12"
                                                /> Active</span> -->
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                </template>
                                <template v-else>
                                  <td style="width: 36.4%;" >
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div  v-b-tooltip.hover
                                          title="No ad group ads were found for this campaign.">
                                            <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <!-- <p style="margin-top: 5px;">
                                              {{ row_secound_value.adGroupName }}
                                            </p> -->
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adGroupName &&
                                                row_secound_value.adGroupName.length > 50
                                                ? row_secound_value.adGroupName.substring(0, 50) + '\n' +
                                                row_secound_value.adGroupName.substring(50)
                                                : row_secound_value.adGroupName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />Ad
                                                Groups</span>
                                              <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                  <feather-icon
                                                  icon="ActivityIcon"
                                                  size="12"
                                                /> Active</span> -->
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                </template>
                                <!-- <td>
                                  {{ row_secound_value.adsetName }}
                                </td> -->
                                <td class="td-width">
                                  {{ row_secound_value.costMicros }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.impressions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.clicks }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpm }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.ctr }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.conversions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cvr }}
                                </td>

                                <td class="td-width">
                                  {{ row_secound_value.cpcl }}
                                </td>
                              </tr>
                              <tr class="animate" v-if="subOpened.includes(rowSecIndex)">
                                <td colspan="14">
                                  <!-- <div style="margin:5px; float: right;">
                                <b-button   class="exportButton"  size="sm" variant="primary" @click="exportData(displayedInsideData)"
                                ><span style="font-weight: bold;">
                                  Export Data
                                  <feather-icon
                                    icon="DownloadIcon"
                                    size="15"
                                    style="margin-left: 5px;"/></span
                              ></b-button>
                              </div> -->
                                  <div class="pl-3">
                                    <table>
                                      <!-- <thead>
                                        <tr>
                                          <th
                                            v-for="(col_three_cols,
                                            thirdRow) in addSubCreativesData"
                                            class="thCreativesHeader"
                                            :key="thirdRow"
                                            :style="{
                                              backgroundColor: col_three_cols
                                                ? col_three_cols.color
                                                : '',
                                            }"
                                          >
                                            {{ addSubCreativesData[thirdRow].title }}
                                          </th>
                                        </tr>
                                      </thead> -->
                                      <tbody>
                                        <tr v-for="(third_row,
                                          rowThirdIndex) in displayedSubInsideData" :key="rowThirdIndex">
                                          <td style="width:34.2% !important">
                                            <div style="display: flex;">
                                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                                  color="skyblue" />
                                              </div>
                                              <div>
                                                <!-- <p style="margin-top: 5px;">
                                                  {{ third_row.adGroupAdName }}
                                                </p> -->
                                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                                  {{ third_row && third_row.adGroupAdName &&
                                                    third_row.adGroupAdName.length > 50
                                                    ? third_row.adGroupAdName.substring(0, 50) + '\n' +
                                                    third_row.adGroupAdName.substring(50)
                                                    : third_row.adGroupAdName }}
                                                </p>
                                                <p style="margin-top: -10px;">
                                                <div style="display: flex; justify-content:space-between">
                                                  <span
                                                    style="border: 1px solid skyblue; border-radius:10px; padding:3px; color:skyblue; font-weight:bold">
                                                    <feather-icon icon="LayoutIcon" size="12" color="skyblue" />
                                                    Ad</span>
                                                </div>
                                                </p>
                                              </div>
                                            </div>
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.costMicros }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.impressions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.clicks }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpm }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.ctr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.conversions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cvr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpcl }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div style="justify-content: center; margin-top: 10px; height: 1;">
                                    <b-pagination v-model="subCurrentTablePageInside"
                                      :total-rows="subTotalPaidRowsInside" :per-page="subTotalPaidInside" first-number
                                      last-number align="right" prev-class="prev-item" next-class="next-item"
                                      class="mt-1 mb-0"></b-pagination>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div style="justify-content: center; margin-top: 10px; height: 1;">
                        <b-pagination v-model="currentTablePageInside" :total-rows="totalPaidRowsInside"
                          :per-page="totalPaidInside" first-number last-number align="right" prev-class="prev-item"
                          next-class="next-item" class="mt-1 mb-0"></b-pagination>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div v-else>
            <table class="creativeTableStyle" v-if="this.ChannelType === 'Meta'">
              <thead>
                <tr>
                  <th style="border: 0.5px solid #e7e7e7;" :style="{ backgroundColor: firstColumnColor }"
                    class="thCreativesHeader">
                    {{ creativeAnalysisData[0].title }}
                  </th>
                  <th
                    style="border: 0.5px solid #e7e7e7; min-width:100px !important; max-width:100px !important; padding:10px 5px !important;"
                    v-for="(col_one_header,
                      fistIndex) in creativeAnalysisData.slice(1)" class="thCreativesHeader" :key="fistIndex" :style="{
                        backgroundColor: col_one_header ? col_one_header.color : '',
                      }">
                    {{ creativeAnalysisData[fistIndex + 1].title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(first_row, firstRowIndex) in displayedData">
                  <!-- 1 - parent level -->
                  <tr :key="firstRowIndex" :class="{ opened: opened.includes(firstRowIndex) }" style="padding: 0px;">
                    <template v-if=" firstRowIndex,
                    first_row && first_row.adset.length > 0">
                    <td style="max-width: 500px !important; min-width: 500px !important;" @click="
                    toggle(
                      firstRowIndex,
                      first_row && first_row.adset
                    )
                    ">
                    <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                      <b-button variant="link" style="padding:0px 2px !important">
                        <span
                          style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                          <div style="display: flex;">
                            <div>
                              <feather-icon :icon="opened.includes(firstRowIndex)
                                ? 'MinusCircleIcon'
                                : 'PlusCircleIcon'
                                " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                            </div>
                            <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                              <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                            </div>
                            <div>
                              <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                {{ first_row && first_row.campaignName }}
                              </p>
                              <p style="margin-top: -10px;">
                              <div style="display: flex;gap:8px">
                                <span
                                  style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                  <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                <span
                                  style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                  <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                              </div>
                              </p>
                            </div>
                          </div>
                          <!-- <span> {{
                            first_row.campaigns[firstRowIndex].campaignName
                          }}</span> -->
                        </span>
                      </b-button>
                    </div>
                  </td>
                    </template>
                <template v-else>
                  <td style="max-width: 500px !important; min-width: 500px !important;">
                  <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                    <b-button variant="link" style="padding:0px 2px !important">
                      <span
                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                        <div style="display: flex;">
                          <div v-b-tooltip.hover
                          title="No ad group ads were found for this campaign.">
                            <feather-icon  size="12" icon="AlertCircleIcon" color="grey" class="ml-1" style="margin-top: 25px;" />
                          </div>
                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                          </div>
                          <div>
                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                              {{ first_row && first_row.campaignName }}
                            </p>
                            <p style="margin-top: -10px;">
                            <div style="display: flex;gap:8px">
                              <span
                                style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                              <span
                                style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                            </div>
                            </p>
                          </div>
                        </div>
                        <!-- <span> {{
                          first_row.campaigns[firstRowIndex].campaignName
                        }}</span> -->
                      </span>
                    </b-button>
                  </div>
                </td>
                </template>
                    <td class="td-width">
                      {{ first_row && first_row.spend }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.impressions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.clicks }}
                    </td>

                    <td class="td-width">
                      {{ first_row && first_row.cpm }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.ctr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.reach }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td>
                    <!-- <td class="td-width">
                      {{ first_row && first_row.webPurchases }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.addToCart }}
                    </td> -->
                    <td class="td-width">
                      {{ first_row && first_row.ROAS }}
                    </td>
                  </tr>
                  <!-- 2 - child level -->
                  <tr class="animate" v-if="opened.includes(firstRowIndex)">
                    <td colspan="14">
                      <div class="pl-3">
                        <table>
                          <!-- <thead>
                            <tr>
                              <th
                                v-for="(col_two_header,
                                secondRow) in subCreativesData"
                                class="thCreativesHeader"
                                :key="secondRow"
                                :style="{
                                  backgroundColor: col_two_header
                                    ? col_two_header.color
                                    : '',
                                }"
                              >
                                {{ subCreativesData[secondRow].title }}
                              </th>
                            </tr>
                          </thead> -->
                          <tbody>
                            <template v-for="(row_secound_value,
                              rowSecIndex) in displayedInsideData">
                              <tr :key="rowSecIndex" :class="{ subopened: subOpened.includes(rowSecIndex) }">
                                <td style="width: 33.7%;" @click="
                                  subToggle(rowSecIndex, row_secound_value.ads)
                                  ">
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div>
                                            <feather-icon :icon="subOpened.includes(rowSecIndex)
                                              ? 'MinusCircleIcon'
                                              : 'PlusCircleIcon'
                                              " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <!-- <p style="margin-top: 5px;">
                                              {{ row_secound_value.adsetName }}-
                                            </p> -->
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adsetName &&
                                                row_secound_value.adsetName.length > 50
                                                ? row_secound_value.adsetName.substring(0, 50) + '\n' +
                                                row_secound_value.adsetName.substring(50)
                                                : row_secound_value.adsetName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />
                                                Ads-Set</span>
                                              <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                  <feather-icon
                                                  icon="ActivityIcon"
                                                  size="12"
                                                /> Active</span> -->
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                <!-- <td>
                                  {{ row_secound_value.adsetName }}
                                </td> -->
                                <td class="td-width">
                                  {{ row_secound_value.spend }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.impressions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.clicks }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpm }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpcl }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.ctr }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.reach }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpcl }}
                                </td>
                                <!-- <td class="td-width">
                                  {{ row_secound_value.webPurchases }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.addToCart }}
                                </td> -->
                                <td class="td-width">
                                  {{ row_secound_value.ROAS }}
                                </td>
                              </tr>
                              <tr class="animate" v-if="subOpened.includes(rowSecIndex)">
                                <td colspan="14">
                                  <!-- <div style="margin:5px; float: right;">
                                <b-button   class="exportButton"  size="sm" variant="primary" @click="exportData(displayedInsideData)"
                                ><span style="font-weight: bold;">
                                  Export Data
                                  <feather-icon
                                    icon="DownloadIcon"
                                    size="15"
                                    style="margin-left: 5px;"/></span
                              ></b-button>
                              </div> -->
                                  <div class="pl-3">
                                    <table>
                                      <!-- <thead>
                                        <tr>
                                          <th
                                            v-for="(col_three_cols,
                                            thirdRow) in addSubCreativesData"
                                            class="thCreativesHeader"
                                            :key="thirdRow"
                                            :style="{
                                              backgroundColor: col_three_cols
                                                ? col_three_cols.color
                                                : '',
                                            }"
                                          >
                                            {{ addSubCreativesData[thirdRow].title }}
                                          </th>
                                        </tr>
                                      </thead> -->
                                      <tbody>
                                        <tr v-for="(third_row,
                                          rowThirdIndex) in displayedSubInsideData" :key="rowThirdIndex">
                                          <td style="width:31.5% !important">
                                            <div style="display: flex;">
                                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                                  color="skyblue" />
                                              </div>
                                              <div>
                                                <!-- <p style="margin-top: 5px;">
                                                  {{ third_row.adName }}
                                                </p> -->
                                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                                  {{ third_row && third_row.adName &&
                                                    third_row.adName.length > 50
                                                    ? third_row.adName.substring(0, 50) + '\n' +
                                                    third_row.adName.substring(50)
                                                    : third_row.adName }}
                                                </p>
                                                <p style="margin-top: -10px;">
                                                <div style="display: flex; justify-content:space-between">
                                                  <span
                                                    style="border: 1px solid skyblue; border-radius:10px; padding:3px; color:skyblue; font-weight:bold">
                                                    <feather-icon icon="LayoutIcon" size="12" color="skyblue" />
                                                    Ad</span>
                                                  <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                      <feather-icon
                                                      icon="ActivityIcon"
                                                      size="12"
                                                    /> Active</span> -->
                                                </div>
                                                </p>
                                              </div>
                                            </div>
                                            <!-- {{ third_row.campaignName }} -->
                                          </td>
                                          <!-- <td>
                                            {{ third_row.adsetName }}
                                          </td> -->
                                          <!-- <td>
                                            {{ third_row.adName }}
                                          </td> -->
                                          <td class="td-width">
                                            {{ third_row.spend }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.impressions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.clicks }}
                                          </td>

                                          <td class="td-width">
                                            {{ third_row.cpm }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpcl }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.ctr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.reach }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpcl }}
                                          </td>
                                          <!-- <td class="td-width">
                                            {{ third_row.webPurchases }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.addToCart }}
                                          </td> -->
                                          <td class="td-width">
                                            {{ third_row.ROAS }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div style="justify-content: center; margin-top: 10px; height: 1;">
                                    <b-pagination v-model="subCurrentTablePageInside"
                                      :total-rows="subTotalPaidRowsInside" :per-page="subTotalPaidInside" first-number
                                      last-number align="right" prev-class="prev-item" next-class="next-item"
                                      class="mt-1 mb-0"></b-pagination>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div style="justify-content: center; margin-top: 10px; height: 1;">
                        <b-pagination v-model="currentTablePageInside" :total-rows="totalPaidRowsInside"
                          :per-page="totalPaidInside" first-number last-number align="right" prev-class="prev-item"
                          next-class="next-item" class="mt-1 mb-0"></b-pagination>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <table class="creativeTableStyle" v-if="this.ChannelType === 'Google-Ads'">
              <thead>
                <tr>
                  <th style="border: 0.5px solid #e7e7e7;" :style="{ backgroundColor: firstColumnColor }"
                    class="thCreativesHeader">
                    {{ googleCreativeAnalysisData[0].title }}
                  </th>
                  <th
                    style="border: 0.5px solid #e7e7e7; min-width:100px !important; max-width:100px !important;padding:12px 5px !important"
                    v-for="(col_one_header,
                      fistIndex) in googleCreativeAnalysisData.slice(1)" class="thCreativesHeader" :key="fistIndex"
                    :style="{
                      backgroundColor: col_one_header ? col_one_header.color : '',
                    }">
                    {{ googleCreativeAnalysisData[fistIndex + 1].title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(first_row, firstRowIndex) in displayedData">
                  <!-- 1 - parent level -->
                  <tr :key="firstRowIndex" :class="{ opened: opened.includes(firstRowIndex) }" style="padding: 0px;">
                    <template v-if="first_row &&  first_row.adGroups && first_row.adGroups.length > 0">
                      <td style="max-width: 500px !important; min-width: 500px !important;" 
                      @click="
                        toggle(
                          firstRowIndex,
                          first_row &&  first_row.adGroups && first_row.adGroups
                        )
                        ">
                        <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                          <b-button variant="link" style="padding:0px 2px !important">
                            <span
                              style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                              <div style="display: flex;">
                                <div>
                                  <feather-icon :icon="opened.includes(firstRowIndex)
                                    ? 'MinusCircleIcon'
                                    : 'PlusCircleIcon'
                                    " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                                </div>
                                <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                  <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                                </div>
                                <div>
                                  <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                    {{ first_row && first_row.campaignName }}
                                  </p>
  
                                  <p style="margin-top: -10px;">
                                  <div style="display: flex;gap:8px">
                                    <span
                                      style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                      <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                    <span
                                      style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                      <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                                  </div>
                                  </p>
                                </div>
                              </div>
                              <!-- <span> {{
                                first_row.campaigns[firstRowIndex].campaignName
                              }}</span> -->
                            </span>
                          </b-button>
                        </div>
                      </td>
                    </template>
                    <template v-else>
                      <td style="max-width: 500px !important; min-width: 500px !important;" 
                     >
                        <div style="display: flex; align-items: left; text-align: left; padding:0px !important">
                          <b-button variant="link" style="padding:0px 2px !important">
                            <span
                              style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap; padding:0px !important">
                              <div style="display: flex;">
                                <div v-b-tooltip.hover
                                title="No ad groups were found for this campaign.">
                                  <feather-icon  icon="AlertCircleIcon" size="12" color="grey" class="ml-1" style="margin-top: 25px;" />
                                </div>
                                <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                  <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25" color="green" />
                                </div>
                                <div>
                                  <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                    {{ first_row && first_row.campaignName }}
                                  </p>
  
                                  <p style="margin-top: -10px;">
                                  <div style="display: flex;gap:8px">
                                    <span
                                      style="border: 1px solid green; border-radius:10px; padding:3px; color:green; font-weight:bold">
                                      <feather-icon icon="LayoutIcon" size="12" color="green" /> Campaign</span>
                                    <span
                                      style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">
                                      <feather-icon icon="ActivityIcon" size="12" /> Active</span>
                                  </div>
                                  </p>
                                </div>
                              </div>
                              <!-- <span> {{
                                first_row.campaigns[firstRowIndex].campaignName
                              }}</span> -->
                            </span>
                          </b-button>
                        </div>
                      </td>
                    </template>
                    <td class="td-width">
                      {{ first_row && first_row.costMicros }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.impressions }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.clicks }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.avg_cpm }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.ctr }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td>

                    <!-- <td class="td-width">
                      {{ first_row && first_row.cpcl }}
                    </td> -->

                    <!-- <td class="td-width">
                      {{ first_row && first_row.webPurchases }}
                    </td>
                    <td class="td-width">
                      {{ first_row && first_row.addToCart }}
                    </td> -->
                    <td class="td-width">
                      {{ first_row && first_row.ROAS }}
                    </td>
                  </tr>
                  <!-- 2 - child level -->
                  <tr class="animate" v-if="opened.includes(firstRowIndex)">
                    <td colspan="14">
                      <div class="pl-3">
                        <table>
                          <!-- <thead>
                            <tr>
                              <th
                                v-for="(col_two_header,
                                secondRow) in subCreativesData"
                                class="thCreativesHeader"
                                :key="secondRow"
                                :style="{
                                  backgroundColor: col_two_header
                                    ? col_two_header.color
                                    : '',
                                }"
                              >
                                {{ subCreativesData[secondRow].title }}
                              </th>
                            </tr>
                          </thead> -->
                          <tbody>
                            <template v-for="(row_secound_value,
                              rowSecIndex) in displayedInsideData">
                              <tr :key="rowSecIndex" :class="{ subopened: subOpened.includes(rowSecIndex) }">
                                <td style="width: 36.4%;" @click="
                                  subToggle(rowSecIndex, row_secound_value.adGroupAds)
                                  ">
                                  <div style="display: flex; align-items: left; text-align: left;">
                                    <b-button variant="link" style="padding:0px 2px !important">
                                      <span
                                        style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                                        <div style="display: flex;">
                                          <div>
                                            <feather-icon :icon="subOpened.includes(rowSecIndex)
                                              ? 'MinusCircleIcon'
                                              : 'PlusCircleIcon'
                                              " size="12" color="#200E6B" class="ml-1" style="margin-top: 25px;" />
                                          </div>
                                          <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                            <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                              color="orange" />
                                          </div>
                                          <div>
                                            <!-- <p style="margin-top: 5px;">
                                              {{ row_secound_value.adGroupName }}
                                            </p> -->
                                            <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                              {{ row_secound_value && row_secound_value.adGroupName &&
                                                row_secound_value.adGroupName.length > 50
                                                ? row_secound_value.adGroupName.substring(0, 50) + '\n' +
                                                row_secound_value.adGroupName.substring(50)
                                                : row_secound_value.adGroupName }}
                                            </p>
                                            <p style="margin-top: -10px;">
                                            <div style="display: flex; justify-content:space-between">
                                              <span
                                                style="border: 1px solid orange; border-radius:10px; padding:3px; color:orange; font-weight:bold">
                                                <feather-icon icon="LayoutIcon" size="12" color="orange" />Ad
                                                Groups</span>
                                              <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                  <feather-icon
                                                  icon="ActivityIcon"
                                                  size="12"
                                                /> Active</span> -->
                                            </div>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </b-button>
                                  </div>
                                </td>
                                <!-- <td>
                                  {{ row_secound_value.adsetName }}
                                </td> -->
                                <td class="td-width">
                                  {{ row_secound_value.costMicros }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.impressions }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.clicks }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.avg_cpm }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpcl }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.ctr }}
                                </td>
                                <td class="td-width">
                                  {{ row_secound_value.cpcl }}
                                </td>

                                <td class="td-width">
                                  {{ row_secound_value.ROAS }}
                                </td>
                              </tr>
                              <tr class="animate" v-if="subOpened.includes(rowSecIndex)">
                                <td colspan="14">
                                  <!-- <div style="margin:5px; float: right;">
                                <b-button   class="exportButton"  size="sm" variant="primary" @click="exportData(displayedInsideData)"
                                ><span style="font-weight: bold;">
                                  Export Data
                                  <feather-icon
                                    icon="DownloadIcon"
                                    size="15"
                                    style="margin-left: 5px;"/></span
                              ></b-button>
                              </div> -->
                                  <div class="pl-3">
                                    <table>
                                      <!-- <thead>
                                        <tr>
                                          <th
                                            v-for="(col_three_cols,
                                            thirdRow) in addSubCreativesData"
                                            class="thCreativesHeader"
                                            :key="thirdRow"
                                            :style="{
                                              backgroundColor: col_three_cols
                                                ? col_three_cols.color
                                                : '',
                                            }"
                                          >
                                            {{ addSubCreativesData[thirdRow].title }}
                                          </th>
                                        </tr>
                                      </thead> -->
                                      <tbody>
                                        <tr v-for="(third_row,
                                          rowThirdIndex) in displayedSubInsideData" :key="rowThirdIndex">
                                          <td style="width:34.2% !important">
                                            <div style="display: flex;">
                                              <div style="height: 50px; width:50px; margin-right:5px; margin-top:5px">
                                                <feather-icon icon="BarChartIcon" style="margin-top: 15px;" size="25"
                                                  color="skyblue" />
                                              </div>
                                              <div>
                                                <!-- <p style="margin-top: 5px;">
                                                  {{ third_row.adGroupAdName }}
                                                </p> -->
                                                <p style="word-break: break-word; white-space: pre-wrap;color: black;">
                                                  {{ third_row && third_row.adGroupAdName &&
                                                    third_row.adGroupAdName.length > 50
                                                    ? third_row.adGroupAdName.substring(0, 50) + '\n' +
                                                    third_row.adGroupAdName.substring(50)
                                                    : third_row.adGroupAdName }}
                                                </p>
                                                <p style="margin-top: -10px;">
                                                <div style="display: flex; justify-content:space-between">
                                                  <span
                                                    style="border: 1px solid skyblue; border-radius:10px; padding:3px; color:skyblue; font-weight:bold">
                                                    <feather-icon icon="LayoutIcon" size="12" color="skyblue" />
                                                    Ad</span>
                                                  <!-- <span style="border: 1px solid blue; border-radius:10px; padding:3px; color:blue; font-weight:bold">   
                                                      <feather-icon
                                                      icon="ActivityIcon"
                                                      size="12"
                                                    /> Active</span> -->
                                                </div>
                                                </p>
                                              </div>
                                            </div>
                                            <!-- {{ third_row.campaignName }} -->
                                          </td>
                                          <!-- <td>
                                            {{ third_row.adsetName }}
                                          </td> -->
                                          <!-- <td>
                                            {{ third_row.adName }}
                                          </td> -->
                                          <td class="td-width">
                                            {{ third_row.costMicros }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.impressions }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.clicks }}
                                          </td>

                                          <td class="td-width">
                                            {{ third_row.avg_cpm }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpcl }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.ctr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.cpcl }}
                                          </td>

                                          <!-- <td class="td-width">
                                            {{ third_row.ctr }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.webPurchases }}
                                          </td>
                                          <td class="td-width">
                                            {{ third_row.addToCart }}
                                          </td> -->
                                          <td class="td-width">
                                            {{ third_row.ROAS }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div style="justify-content: center; margin-top: 10px; height: 1;">
                                    <b-pagination v-model="subCurrentTablePageInside"
                                      :total-rows="subTotalPaidRowsInside" :per-page="subTotalPaidInside" first-number
                                      last-number align="right" prev-class="prev-item" next-class="next-item"
                                      class="mt-1 mb-0"></b-pagination>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div style="justify-content: center; margin-top: 10px; height: 1;">
                        <b-pagination v-model="currentTablePageInside" :total-rows="totalPaidRowsInside"
                          :per-page="totalPaidInside" first-number last-number align="right" prev-class="prev-item"
                          next-class="next-item" class="mt-1 mb-0"></b-pagination>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="justify-content: center; margin-top: 10px; height: 1;">
      <b-pagination
        v-model="currentTablePage"
        v-if="this.PaidData.length > 0"
        :total-rows="totalPaidRows"
        :per-page="totalPaid"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      ></b-pagination>
    </div> -->
  </div>
</template>

<script>
import { BPagination, BButton ,BLink,BCardText} from "bootstrap-vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import client from "@/router/client";
export default {
  components: { BPagination, BButton, multiChannelSpinner,BLink,BCardText },
  data() {
    return {
      subAdsData: [],
      searchTerm: "",
      creativeAnalysisData: [
        { title: "Campaign Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Reach", color: "#ffffff" },
        { title: "CPCL", color: "#ffffff" },
        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
      ],
      subCreativesData: [
        // { title: "Campaign Name", color: "#ffffff" },
        { title: "AdSet Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Reach", color: "#ffffff" },
        { title: "CPCL", color: "#ffffff" },

        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
      ],
      addSubCreativesData: [
        // { title: "Campaign Name", color: "#ffffff" },
        // { title: "AdSet Name", color: "#ffffff" },
        { title: "Ad Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Reach", color: "#ffffff" },
        { title: "CPCL", color: "#ffffff" },
        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
      ],
      googleCreativeAnalysisData: [
        { title: "Campaign Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "CPCL", color: "#ffffff" },
        // { title: "CPCL", color: "#ffffff" },

        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
      ],
      googleSubCreativesData: [
        // { title: "Campaign Name", color: "#ffffff" },
        { title: "AdSet Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Reach", color: "#ffffff" },
        { title: "CPCL", color: "#ffffff" },

        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
      ],
      googleAddSubCreativesData: [
        // { title: "Campaign Name", color: "#ffffff" },
        // { title: "AdSet Name", color: "#ffffff" },
        { title: "Ad Name", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "Reach", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        { title: "CPCL", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
      ],




      //leads
      // "spends": "Spends",
      //     "impressions": "Impressions",
      //     "clicks": "Clicks",
      //     "cpm": "CPM",
      //     "cpcl": "CPC",
      //     "ctr": "CTR %",
      //     "conversions": "Leads",
      //     "cvr": "CVR",
      //     "cpcl": "CPL",

      leadsCreativeAnalysisData: [
        { title: "Campaign Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Leads", color: "#ffffff" },
        { title: "CVR", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },

      ],
      leadSubCreativesData: [
        // { title: "Campaign Name", color: "#ffffff" },
        { title: "AdSet Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Leads", color: "#ffffff" },
        { title: "CVR", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        // { title: "Spends", color: "#ffffff" },
        // { title: "Impressions", color: "#ffffff" },
        // { title: "Clicks", color: "#ffffff" },
        // { title: "CPM", color: "#ffffff" },
        // { title: "CPC", color: "#ffffff" },
        // { title: "CTR %", color: "#ffffff" },
        // { title: "Reach", color: "#ffffff" },
        // { title: "CPCL", color: "#ffffff" },

        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        // { title: "ROAS", color: "#ffffff" },
      ],
      leadAddSubCreativesData: [
        // { title: "Campaign Name", color: "#ffffff" },
        // { title: "AdSet Name", color: "#ffffff" },
        { title: "Ad Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Leads", color: "#ffffff" },
        { title: "CVR", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        // { title: "Spends", color: "#ffffff" },
        // { title: "Impressions", color: "#ffffff" },
        // { title: "Clicks", color: "#ffffff" },
        // { title: "CPM", color: "#ffffff" },
        // { title: "CPC", color: "#ffffff" },
        // { title: "CTR %", color: "#ffffff" },
        // { title: "Reach", color: "#ffffff" },
        // { title: "CPCL", color: "#ffffff" },
        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
      ],
      leadAoogleCreativeAnalysisData: [
        { title: "Campaign Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Leads", color: "#ffffff" },
        { title: "CVR", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        // { title: "CPCL", color: "#ffffff" },

        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        // { title: "ROAS", color: "#ffffff" },
      ],
      leadAoogleSubCreativesData: [
        // { title: "Campaign Name", color: "#ffffff" },
        { title: "AdSet Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Leads", color: "#ffffff" },
        { title: "CVR", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },

        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        // { title: "ROAS", color: "#ffffff" },
      ],
      leadAoogleAddSubCreativesData: [
        // { title: "Campaign Name", color: "#ffffff" },
        // { title: "AdSet Name", color: "#ffffff" },
        { title: "Ad Name", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CPM", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Leads", color: "#ffffff" },
        { title: "CVR", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        // { title: "Web Purchases", color: "#ffffff" },
        // { title: "Add To Cart", color: "#ffffff" },
        // { title: "ROAS", color: "#ffffff" },
      ],
      currentTablePage: 1,
      firstColumnColor: "#000000",
      totalPaid: 10,
      opened: [],
      subOpened: [],
      toggleFlag: false,
      keyWordInsideData: [],
      currentTablePageInside: 1,
      totalPaidInside: 10,
      subCurrentTablePageInside: 1,
      subTotalPaidInside: 10,
    };
  },
  props: {
    clientsLength:{
      type: Number,
    },
    PaidData: {
      type: Array,
      default: []
    },
    SummaryTotalData: {
      type: Object,
    },
    SearchData: {
      type: String,
    },
    TableType: {
      type: String,
    },
    ChannelType: {
      type: String,
    },
    isAnalysisDataFetched: {
      type: Boolean,
    },
    selectedBusinessType: {
      type: String,
    },
    selectedSlug: {
      type: String,
    },
    selectedDataViewType: {
      type: String,
    },
    selectedIntegration: {
      type: Object,
    }
  },
  computed: {
    calculateTotal() {
      return (metric) => {
        return this.PaidData.reduce((total, item) => total + item[metric], 0);
      };
    },
    totalPaidRows() {
      if (this.TableType === "creativeAnalysisData") {
        return Object.keys(this.PaidData[1]).length;
      }
      return this.PaidData.length;
    },
    totalPaidRowsInside() {
      return this.keyWordInsideData.length;
    },
    subTotalPaidRowsInside() {
      return this.subAdsData.length;
    },
    displayTotalData() {
      return this.SummaryTotalData;
    },
    displayedData() {
      const startIndex = (this.currentTablePage - 1) * this.totalPaid;
      const endIndex = startIndex + this.totalPaid;
      return this.PaidData.slice(startIndex, endIndex);
    },
    displayedInsideData() {
      const startIndex =
        (this.currentTablePageInside - 1) * this.totalPaidInside;
      const endIndex = startIndex + this.totalPaidInside;
      return this.keyWordInsideData.slice(startIndex, endIndex);
    },
    displayedSubInsideData() {
      const startIndex =
        (this.currentTablePageInside - 1) * this.subTotalPaidInside;
      const endIndex = startIndex + this.subTotalPaidInside;
      return this.subAdsData.slice(startIndex, endIndex);
    },
  },
  methods: {
    onboardingClick(){
      this.$router.push(`/new-user-onbaord`);
    },
    toggle(rowIndex, data) {
      if (this.opened.length > 0) {
        const previousIndex = this.opened[0];
        this.opened = [];
        if (previousIndex === rowIndex) {
          return;
        }
      }
      this.keyWordInsideData = [...data];
      this.opened.push(rowIndex);
      this.subOpened = [];
    },
    subToggle(rowIndex, data) {
      if (this.subOpened.length > 0) {
        const previousIndex = this.subOpened[0];
        this.subOpened = [];
        if (previousIndex === rowIndex) {
          return;
        }
      }
      this.subAdsData = [...data];
      this.subOpened.push(rowIndex);
    },
    isTableItemClicked() {
      this.$emit("isTableItemClicked", true);
    },
    handlePageInputChange() {
      if (this.goToPage < 1) {
        this.goToPage = 1;
      } else if (this.goToPage > this.totalPages) {
        this.goToPage = this.totalPages;
      }
    },
    goToRequestedPage() {
      const requestedPage = parseInt(this.goToPage) - 1;
      if (requestedPage >= 0 && requestedPage < this.totalPages) {
        this.currentPage = requestedPage;
      }
    },
    sortByFirstColumn() {
      if (this.firstColumnSortOrder === "asc") {
        // Sort in ascending order
        this.sortedData = [...this.displayedData].sort((a, b) =>
          a[0].localeCompare(b[0])
        );
        this.firstColumnSortOrder = "desc";
      } else {
        // Sort in descending order
        this.sortedData = [...this.displayedData].sort((a, b) =>
          b[0].localeCompare(a[0])
        );
        this.firstColumnSortOrder = "asc";
      }
    },
    getCellStyle(columnColor, value) {
      if (value === 0) {
        return `background-color: white; text-align: center; color: #000000;`;
      }
      const ranges = [
        { min: 1, max: 999999 },
        { min: 100000, max: 99999999 },
      ];
      let opacity = 0;
      let rgbColor = "";
      for (const range of ranges) {
        if (value >= range.min && value <= range.max) {
          const rangeSize = range.max - range.min;
          opacity = (value - range.min) / rangeSize;
          opacity = opacity * 0.5 + 0.5;
          opacity = opacity.toFixed(2);
          rgbColor = this.hexToRgb(columnColor);
          break;
        }
      }
      return `background-color: rgba(${rgbColor}, ${opacity}); text-align: center; color: #555555;`;
    },
    hexToRgb(hex) {
      hex = hex.replace(/^#/, "");
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`;
    },
    exportData(data) {
      // console.log(data);
      // Helper function to escape and quote each value
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          // Handle null and undefined
          return '""';
        }
        // Convert to string, escape double quotes by doubling them, and wrap in double quotes
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Generate CSV header
      const header = Object.keys(data[0])
        .map((field) => escapeCSV(field))
        .join(",");

      // Generate CSV rows
      const rows = data.map((item) =>
        Object.values(item)
          .map((value) => escapeCSV(value))
          .join(",")
      );

      // Combine header and rows, separated by newline
      const csv = [header].concat(rows).join("\n");

      // Create a Blob for the CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create a temporary download link and trigger the download
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `Keyword_Level_Performance.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
      }
    },
  },
  watch: {
    PaidData(value) {
      this.opened = [];
      this.keyWordInsideData = []
      this.subAdsData = []
      this.subOpened = []
    },
  },
};
</script>

<style scoped>
.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Adds spacing between elements */
  text-align: center;
}
.sort-icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent #000;
}

.asc {
  border-width: 4px 4px 0 4px;
  border-color: #000 transparent transparent transparent;
}

.desc {
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #000 transparent;
}

/* Add your custom CSS styling here */

.table-container {
  width: 100%;
  /* overflow-x: auto; */
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid rgb(215, 215, 215);
}

th,
td {
  padding: 0px;
  text-align: center;
  color: black;
  border: 0.5px solid #e7e7e7;
}

td:hover {
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}

th {
  text-align: center;
  font-weight: bold;
  border: none;
  color: #ef8b6c;
  font-weight: bold;
  background-color: #ffffff !important;
}

.summaryTableForDealer td {
 color: black !important;
  font-size: 12px !important;
  padding: 5px;
}

.keywordTableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.creativeTableStyle td {
 color: black !important;
  font-size: 12px !important;
  
}

.creativeTableStyle td:hover {
  background-color: rgb(250, 250, 250);
}

.thforGMBDealers,
.thCreativesHeader {
  text-align: center;
  font-weight: bold;
  border: none;
  color: black !important;
  font-weight: bold;
  background-color: #ffffff !important;
}

.pagination {
  margin-top: 10px;
  text-align: center;
  float: right;
}

.keyword-cell {
  display: flex;
  align-items: center;
}

.pagination-button {
  background-color: white;
  color: #fff;
  border: 1px solid #0d1282;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
}

.pagination-button:disabled {
  background-color: #fcf9f9;
  cursor: not-allowed;
  opacity: 0.3;
}

.pagination-label {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.page-input {
  width: 40px;
  text-align: center;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.go-button {
  background-color: #0d1282;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 10px;
  margin-left: 5px;
}

.opened {
  background-color: #e5f1f8;
  border: none;
}

.opened td {
  opacity: 1;
  color: black;
  transition: opacity 0.5s;
  /* You can adjust the transition properties */
}

.subopened {
  background-color: #F5EFFF;
  border: none;
}

.subopened td {
  opacity: 1;
  color: black;
  transition: opacity 0.5s;
  /* You can adjust the transition properties */
}

.closed td {
  opacity: 0;
  color: black;
  transition: opacity 0.8s;
  /* You can adjust the transition properties */
  display: none;
}

.animate {
  animation-duration: 1s;
  /* Increase the duration to 1s for a smoother effect */
  animation-name: animate-fade-move;
  animation-fill-mode: forwards;
  /* Change to 'forwards' to keep the final state */
}

@keyframes animate-fade-move {
  0% {
    opacity: 0;
    transform: translateX(50px);
    /* Adjust the value based on your needs */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}

.td-width {
  min-width: 76px !important;
  max-width: 76px !important
}
</style>
