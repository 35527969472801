<template>
    <div>
      <div id="scanning-container">
        <div class="scanning-wrapper scanning-box">
          <div class="file-types">
            <b-icon font-scale="10" color="#532b7b" style="margin-top:30px; margin-left:40px" icon="file-earmark-image"></b-icon>
          </div>
          <div class="scanner-bar"></div>
        </div>
      </div>
      <p style="text-align: center; font-weight:bold; font-size:11px">AI is analyzing your Creative, Please wait a moment...</p>
    </div>
  </template>
  
  <script>
  import { BIcon} from 'bootstrap-vue';
  export default {
    components: {
    BIcon,
  }
  };
  </script>
  
  <style scoped>
  #scanning-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .scanning-wrapper {
    width: 400px;
    height: 15em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .scanning-box {
    border: 2px dashed #532b7b;
    border-radius: 10px;
  }
  
  .corner {
    position: absolute;
    width: 100%;
    height: 3em;
  }
  
  .corner::before,
  .corner::after {
    height: 3em;
    width: 3em;
    border: 0 solid #532b7b;
    content: '';
    position: absolute;
  }
  
  .bottom-corners::before {
    border-bottom-width: 3px;
    border-left-width: 3px;
  }
  
  .bottom-corners::after {
    border-bottom-width: 3px;
    border-right-width: 3px;
  }
  
  .top-corners::before {
    border-top-width: 3px;
    border-left-width: 3px;
  }
  
  .top-corners::after {
    border-top-width: 3px;
    border-right-width: 3px;
  }
  
  .scanner-bar {
    height: 80%;
    width: 10px;
    background-color:#532b7b;
    position: absolute;
    left: 10%;
    animation: scan-animation 2s cubic-bezier(0.46, 0.55, 0.59, 0.95) alternate infinite;
  }
  
  .file-types {
    height: 100%;
    width: 200px;
    position: relative;
  }
  
  .file-types-carousel {
    font-size: 10em;
    height: 1em;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: file-scale-animation 2s infinite;
  }
  
  .file-types-track {
    animation: file-translate-animation 12s steps(6) infinite;
  }
  
  @keyframes scan-animation {
    0%, 40% {
      left: 5%;
    }
    70%, 100% {
      left: 95%;
    }
  }
  
  @keyframes file-translate-animation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-6em);
    }
  }
  </style>