<template>
  <div ref="pdfContainer" class="analysis-container-main pdf-break-avoid">
    <div class="analysis-container">
      <!-- Header -->
      <h2 class="title">AI Creative Analysis For {{ analysisData.title }}</h2>
      <p class="subtitle">Generated analysis to improve your creative assets.</p>

      <div class="overall-score">
        <p class="score-title">Overall Score</p>
        <p class="score-value text-warning">{{ analysisData.campaign_score }}</p>
      </div>

      <div v-if="analysisData.imageUrl" class="main-preview-image-container">
        <div class="preview-image-container mb-2">
          <div class="preview">
            <img ref="imageRef" :src="analysisData.imageUrl" alt="Uploaded Creative Image"
              style="max-width: 40%; max-height: 100%; border-radius: 12px;" />
          </div>
        </div>
      </div>

      <!-- Score Breakdown -->
      <div class="score-section"
        v-if="analysisData && analysisData.key_metrics && Object.keys(analysisData.key_metrics).length">
        <div class="score-card" v-for="(value, key) in analysisData.key_metrics" :key="key">
          <p class="score-label">{{ key && formatKey(key) }}</p>
          <p class="score-value" :class="getScoreClass(value)">{{ value }}</p>
        </div>
      </div>

      <!-- Key Highlights -->
      <div class="highlights" v-if="analysisData && analysisData.key_highlights && analysisData.key_highlights.length">
        <h3 class="highlight-title">Key Highlights</h3>
        <div class="highlight-section" v-for="(text, index) in analysisData.key_highlights" :key="index">
          <p>{{ text }}</p>
        </div>
      </div>

      <!-- Identified Issues -->
      <div class="issues"
        v-if="analysisData && analysisData.identified_issues && analysisData.identified_issues.length">
        <h3 class="highlight-title">Identified Issues</h3>
        <div v-for="(issue, index) in analysisData.identified_issues" :key="index" class="issue-card"
          :class="issue.severity.toLowerCase()">
          <h4>{{ issue.issue }} (Severity: {{ issue.severity }})</h4>
        </div>
      </div>

      <!-- Recommendations -->
      <div class="recommendations"
        v-if="analysisData && analysisData.recommendations && analysisData.recommendations.length">
        <h3 class="highlight-title">Recommendations</h3>
        <ul>
          <li v-for="(text, index) in analysisData.recommendations" :key="index">
            {{ text }}
          </li>
        </ul>
      </div>

      <!-- Areas of Improvement -->
      <div class="improvements"
        v-if="analysisData && analysisData.areas_of_improvement && analysisData.areas_of_improvement.length">
        <h3 class="highlight-title">Areas of Improvement</h3>
        <ul>
          <li v-for="(text, index) in analysisData.areas_of_improvement" :key="index">
            {{ text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  props: {
    analysisData: {
      type: Object,
      required: true
    },
    imageUrl: {
      type: String
    }
  },
  methods: {
    async convertToBase64(url) {
      try {
        const response = await fetch(url, { mode: "cors" }); // Ensure CORS is allowed
        const blob = await response.blob();

        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result); // Base64 string
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Failed to load image:", error);
        return "";
      }
    }
    ,
    async downloadPDF() {
      if (this.imageUrl) {
        this.imageBase64 = await this.convertToBase64(this.imageUrl);
      }

      const element = this.$refs.pdfContainer;

      // 1️⃣ Dynamically inject print-only CSS
      const style = document.createElement('style');
      style.innerHTML = `
    @media print {
      .pdf-break-avoid {
        page-break-inside: avoid !important;
        break-inside: avoid !important;
      }
    }
  `;
      document.head.appendChild(style);

      // 2️⃣ Proceed with PDF generation
      html2pdf()
        .from(element)
        .set({
          margin: 10,
          filename: `${this.analysisData.title}_AI_Creative_Analysis.pdf`,
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 3, useCORS: true, logging: false },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        })
        .save()
        .then(() => {
          // 3️⃣ Clean up: remove the temporary style
          document.head.removeChild(style);
        });
    }

    ,
    formatKey(key) {
      if (typeof key !== 'string') {
        return String(key); // Convert key to string if it's not already
      }
      return key && key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
    },
    getScoreClass(value) {
      return value == "low" ? "low" : value == "high" ? "high" : "medium";
    }
  }
};
</script>

<style lang="scss">
@media print {
  .pdf-break-avoid {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }
}


.analysis-container-main {

  /* Main Container */
  .analysis-container {
    width: 100%;
    margin: auto;
    padding: 20px;
    background: #f8f9fc;
    border-radius: 8px;
    text-align: center;
  }

  /* Title */
  .title {
    font-size: 28px;
    font-weight: bolder;
    color: #007bff;
  }

  .subtitle {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 20px;
  }

  /* Overall Score */
  .overall-score {
    background: #e9ecef;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;

    .score-title {
      font-size: 18px;
      font-weight: bold;
      color: #343a40;
    }

    .score-value {
      font-size: 32px;
      font-weight: bold;
      color: #333;
    }
  }

  .issues {
    text-align: left;
    margin-top: 20px;
  }

  .issue-card {
    background: #fff3cd;
    /* Light yellow */
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    border-left: 5px solid #ffae42;
    /* Orange border for medium severity */

    &.high {
      border-left-color: red;
      background: #f8d7da;
      /* Light red */
    }

    &.low {
      border-left-color: green;
      background: #d4edda;
      /* Light green */
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
    }
  }

  /* Score Section */
  .score-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .score-card {
    flex: 1;
    background: #e9ecef;
    padding: 15px;
    border-radius: 8px;

    .score-label {
      font-size: 14px;
      font-weight: bold;
      color: #343a40;
    }

    .score-value {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .low {
    color: red;
  }

  .high {
    color: green;
  }

  .medium {
    color: orange;
  }

  /* Highlights Section */
  .highlights,
  .issues,
  .recommendations,
  .improvements {
    text-align: left;
    margin-top: 20px;
    padding: 10px 0;
  }

  .highlight-title {
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
  }

  .highlight-section {
    margin-top: 10px;

    h4 {
      font-size: 16px;
      font-weight: bold;
      color: #343a40;
    }

    p {
      font-size: 14px;
      color: #555;
    }
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;

    li {
      font-size: 14px;
      color: #555;
      margin-top: 5px;
    }
  }

  /* Flipbook */
  .preview-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .preview {
      width: 100%;
      height: 100%;
      background: #fafafa;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  .main-preview-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    /* Ensures full height without a fixed height */
    width: 100%;
  }
}
</style>
